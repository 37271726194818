/* eslint-disable no-nested-ternary */
import { format } from 'date-fns'
import useMediaQuery from 'hooks/useMediaQuery'
import { ItemOrder, Status } from 'hooks/useOrderB2c'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { api } from 'services/api'
import { MOBILE_WIDTH, SERVICE_LINK } from 'utils/constants'
import { getApp } from 'utils/sub-domains'
import Steps from './Steps'
import * as Style from './styles'

export interface OrderProps {
  id?: number
  uuid?: string
  amount?: string
  address_number?: string
  cep?: string
  city?: string
  complement?: string
  coupon?: Coupon
  state?: string
  district?: string
  createdAt: Date
  status?: Status
  shipping?: string
  discount?: string
  shippingDiscount?: string
  interest_amount?: string
  items: ItemOrder[]
  payments?: Payment[]
  customer_interest?: boolean
}
interface Coupon {
  type?: string
  type_shipping?: string
  value?: string
  value_shipping?: string
  name?: string
  id?: number
}

interface Payment {
  id: number
  installments: string
  method: string
  status: string
}

interface PaymentsMethod {
  credit_card: string
  pix: string
  boleto: string
  debit_card: string
}

const OrderStatus = () => {
  const { uuid } = useParams() as any
  const brand = getApp()
  const [order, setOrder] = useState<OrderProps>({
    createdAt: new Date(),
    items: [],
  })

  const isPaid = order.payments?.find(payment => payment.status === 'paid')

  const PAYMENTS_METHOD: PaymentsMethod = {
    credit_card: 'Cartão de Crédito',
    pix: 'Pix',
    boleto: 'Boleto',
    debit_card: 'Cartão de Débito',
  }

  const history = useHistory()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const defaultShippingValue = Number(order?.shipping) || 140
  const frete = defaultShippingValue - Number(order.shippingDiscount)

  const link_service = SERVICE_LINK.find(app => brand === app.brand)

  const link = `https://${brand}.cannect.life/checkout/${order?.uuid}`

  const [text, setText] = useState('COPIAR')
  async function copyUrl() {
    navigator.clipboard.writeText(link ?? '')
    setText('COPIADO!')
  }

  const order_amount = order?.customer_interest && Number(order?.interest_amount) > 0 ? Number(order?.amount) - Number(order?.interest_amount) : Number(order?.amount)

  const order_amount_without_shipping = (order_amount - Number(order?.shipping)) + Number(order?.discount)


  const items = order?.items.reduce(
    (acc, item) => {
      const value = order?.coupon?.id ? item?.unitPrice : item?.imputed_value
      const price = value !== null && value !== undefined && Number(value) >= 0 ? Number(value) : Number(item?.unitPrice)

      acc.subtotal += price * item.quantity
      acc.total = acc.subtotal + frete - Number(acc.discount)

      return acc
    },
    {
      total: 0,
      subtotal: 0,
      discount: order?.discount,
      shippingDiscount: 0,
      productDiscount: 0,
    }
  )

  const getOrder = async () => {
    try {
      const { data } = await api.get(`/order_b2b_status/${uuid}`)
      console.log(data)

      setOrder(data.order)

      return order
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getOrder()
  }, [])

  return (
    <Style.Container isMobile={isMobile}>
      <Steps />
      <Style.Content isMobile={isMobile}>
        <h1>Resumo da compra</h1>
        <Style.HeaderContent isMobile={isMobile}>
          <div className="box white">
            <div className="box-content">
              <h2>Status de pagamento</h2>
              <span>{order?.status?.payment_status?.translated_name}</span>
              <span>Data do Pedido: {format(new Date(order.createdAt), 'dd/MM/yyyy')}</span>
            </div>
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Método de pagamento</h2>
              <span>
                {order?.payments && order.payments.length > 0
                  ? isPaid
                    ? PAYMENTS_METHOD[isPaid.method as keyof PaymentsMethod]
                    : '---'
                  : '---'}
              </span>
              <span>Data do Pedido: {format(new Date(order.createdAt), 'dd/MM/yyyy')}</span>
            </div>
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Endereço de entrega</h2>
              <span>
                {order?.district}, {order?.address_number}
              </span>
              <span>
                {order?.city}, {order?.state}, {order?.cep}
              </span>
            </div>
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Alguma dúvida? Fale conosco:</h2>
              {link_service ? (
                <a href={link_service.link} target="_blank" rel="noreferrer">Falar com atendente</a>
              ) : (
                <span>Falar com atendente</span>
              )}
            </div>
          </div>
        </Style.HeaderContent>
        <Style.ProductsInfo isMobile={isMobile}>
          <Style.HeaderInfo isMobile={isMobile}>
            <div className="content-info">
              <p>Nº do pedido: {order?.id}</p>
            </div>
            <div className="content-transport">
              {/* <p>TRANSPORTADOR: FEDEX</p> */}
            </div>
            <div className="badge-status">
              <span>{order?.status?.order_status?.translated_name}</span>
            </div>
          </Style.HeaderInfo>
          <Style.ProductList>
            {!isMobile && (
              <Style.HeaderRow>
                <Style.HeaderColumn style={{ width: '65%' }}>PRODUTOS</Style.HeaderColumn>
                <Style.HeaderColumn>QUANT.</Style.HeaderColumn>
                <Style.HeaderColumn>VALOR UN.</Style.HeaderColumn>
              </Style.HeaderRow>
            )}
            {order.items?.map(item => (
              <Style.ProductRow>
                <Style.ProductName>
                  <img
                    src={
                      item?.product?.media.length > 0
                        ? item?.product?.media[0].path
                        : 'https://s3.amazonaws.com/cannect.portal.upload/produtos/14571/foto+1.jpg'
                    }
                    alt="Imagem produto"
                  />
                  <span>{item?.product?.translated_name}</span>
                </Style.ProductName>
                <Style.ProductQuantity>
                  <span>{item?.quantity}</span>
                </Style.ProductQuantity>
                <Style.ProductColumn>
                  <span>
                    {new Intl.NumberFormat('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      currencyDisplay: 'symbol',
                    }).format(Number(item?.imputed_value !== '0.00' ? item?.imputed_value : item.unitPrice))}
                  </span>
                </Style.ProductColumn>
              </Style.ProductRow>
            ))}
          </Style.ProductList>
          <Style.Resume>
            <Style.ResumeTitles>
              <p>Subtotal</p>
              <p>Custo de importação</p>
              <p>Descontos</p>
              <span>Total</span>
            </Style.ResumeTitles>
            <Style.ResumeValues>
              <p>
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(order_amount_without_shipping)}
              </p>
              <p>
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(Number(order?.shipping))}
              </p>
              <p>
                -{' '}
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(Number(order?.discount))}
              </p>
              <span>
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(order_amount)}
              </span>
            </Style.ResumeValues>
          </Style.Resume>
        </Style.ProductsInfo>
      </Style.Content>
    </Style.Container>
  )
}

export default OrderStatus
